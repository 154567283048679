import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo';
import Layout from '../components/layout'
import TestimonialBlock from '../components/testimonial-block/testimonial-block';
import PrimaryBanner from '../components/primary-banner/primary-banner';
// import PrimaryBannerVideo from '../components/primary-banner-video/primary-banner-video';
import SolutionsBlock from '../components/solutions-block-ecompare/solutions-block-ecompare';
import ItemsKeyFeatures from '../components/items-key-features/items-key-features';
import FaqBlock from '../components/faq-block/faq-block';
import OverlayBlock from '../components/overlay-block/overlay-block';

class ECompare extends React.Component {
    render() {
        const ecomparePrimaryBanner = get(this, 'props.data.ecomparePrimaryBanner');
        // const eComparePrimaryBannerVideo = get(this, 'props.data.eComparePrimaryBannerVideo');
        const ecompareSolutionsBlock = get(this, 'props.data.eComparePageSolutionsBlock')
        const eCompareItemsKeyFeaturesHeading = get(this, 'props.data.eCompareItemsKeyFeaturesHeading');
        const eCompareItemsKeyFeatures = get(this, 'props.data.eCompareItemsKeyFeatures');
        const ecompareTestimonialHeading = get(this, 'props.data.ecompareTestimonialHeading');
        const allContentfulTestimonialBlock = get(this, 'props.data.allContentfulTestimonialBlock');
        const eCompareFaqBlockHeading = get(this, 'props.data.eCompareFaqBlockHeading');
        const eCompareFaqBlock = get(this, 'props.data.eCompareFaqBlock');
        const eCompareOverlayBlock = get(this, 'props.data.eCompareOverlayBlock');

        return (
            <Layout location={this.props.location}>
                <Seo title="eCompare" />
                    <div className="container-fluid container-fluid--top">
                        <PrimaryBanner primaryBanner="ecompare-primary-banner" logo={ecomparePrimaryBanner.logo.url} headingText={ecomparePrimaryBanner.title} subtitle={ecomparePrimaryBanner.subtitle} image={ecomparePrimaryBanner.backgroundImage.url} buttonPrimaryUrl={ecomparePrimaryBanner.primaryButtonUrl} buttonPrimaryClass="button-primary-ecompare" buttonPrimaryText={ecomparePrimaryBanner.primaryButtonText} buttonSecondaryUrl={ecomparePrimaryBanner.secondaryButtonUrl} buttonSecondaryClass="button-secondary-ecompare" buttonSecondaryText={ecomparePrimaryBanner.secondaryButtonText} />
                        {/* <PrimaryBannerVideo
                            video={eComparePrimaryBannerVideo.video ? eComparePrimaryBannerVideo.video.url : null}
                            headingText={eComparePrimaryBannerVideo.headingText}
                            descriptionText={eComparePrimaryBannerVideo.descriptionText}
                            buttonUrl={eComparePrimaryBannerVideo.buttonUrl}
                            buttonText={eComparePrimaryBannerVideo.buttonText}
                        /> */}
                        <SolutionsBlock solutions={ecompareSolutionsBlock} />
                        <ItemsKeyFeatures itemsKeyFeaturesBlockClass="ecompare-items-key-features" itemsKeyFeaturesHeading={eCompareItemsKeyFeaturesHeading} ItemsKeyFeatures={eCompareItemsKeyFeatures.nodes} />
                        <TestimonialBlock ecompareTestimonialBlockClass="ecompare-testimonial-block" testimonialHeading={ecompareTestimonialHeading} testimonials={allContentfulTestimonialBlock.nodes} />
                        <FaqBlock faqBlockHeading={eCompareFaqBlockHeading} faqBlock={eCompareFaqBlock.nodes} />
                        <OverlayBlock ecompareOverlayBlockClass="ecompare-overlay-block" headingText={eCompareOverlayBlock.title} buttonUrl={eCompareOverlayBlock.buttonUrl} buttonText={eCompareOverlayBlock.buttonText} image={eCompareOverlayBlock.backgroundImage.url} />
                    </div>
            </Layout>
        )
    }
}

export default ECompare;

export const query = graphql`
query ecompareQuery {
    ecomparePrimaryBanner: contentfulPrimaryBanner(primaryBannerName: {eq: "ecomparePrimaryBanner"}) {
        logo {
            url
        }
        title
        subtitle
        primaryBannerName
        primaryButtonUrl
        primaryButtonText
        secondaryButtonUrl
        secondaryButtonText
        backgroundImage {
            url
        }
    }
    eComparePrimaryBannerVideo: contentfulPrimaryBannerVideo(primaryBannerVideoName: {eq: "eComparePrimaryBannerVideo"}) {
        headingText
        descriptionText {
            raw
        }
        buttonUrl
        buttonText
        video {
            url
        }
    }
    eComparePageSolutionsBlock: contentfulSolutionsBlock(solutionsBlockName: {eq: "ecompareSolutionsBlock"}) {
        headingTitle
        headingSubtitle
        titleOne
        descriptionOne
        iconOne {
          url
        }
        titleTwo
        descriptionTwo
        iconTwo {
          url
        }
        titleThree
        descriptionThree
        iconThree {
          url
        }
      }
    eCompareItemsKeyFeaturesHeading: contentfulMulticolumnHeading (multicolumnHeadingName: {eq: "eCompareItemsKeyFeaturesHeading"}) {
        titleText
        descriptionText
    }
    eCompareItemsKeyFeatures: allContentfulItemsKeyFeatures (sort: { fields: orderId, order: ASC } filter: {website: {eq: "eCompare"}}) {
        nodes {
          orderId
          headingText
          descriptionText {
            raw
          }
          image {
            url
          }
        }
    }
    ecompareTestimonialHeading: contentfulTestimonialHeading (testimonialHeadingName: {eq: "ecompareTestimonialHeading"}) {
        quoteTitle
      }
    allContentfulTestimonialBlock(filter: {website: {eq: "eCompare"}}) {
        nodes {
            quote
            clientName
            positionAndCompany
        }
    }
    eCompareFaqBlockHeading: contentfulMulticolumnHeading (multicolumnHeadingName: {eq: "eCompareFaqBlockHeading"}) {
        titleText
        descriptionText
    }
    eCompareFaqBlock: allContentfulFaqBlock (sort: { fields: orderId, order: ASC } filter: {website: {eq: "eCompare"}}) {
        nodes {
          orderId
          questionText
          answerText {
            raw
          }
        }
    }
    eCompareOverlayBlock: contentfulOverlayBlock(overlayBlockName: {eq: "eCompareOverlayBlock"}) {
        title
        buttonUrl
        buttonText
        backgroundImage {
                url
        }
    }
}
`
