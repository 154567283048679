import React from 'react'
import { Link } from 'gatsby'
import './primary-banner.scss'

export default function PrimaryBanner(props) {
  return (
    <div className={`primary-banner ${props.primaryBanner}`} style={{backgroundImage: `url(${props.image})`}}>
        {props.video &&
            <video src={props.video} playsInline loop muted autoPlay />
        }
        <div className="container-fluid">
            {props.logo &&
                <img className="primary-banner__logo" src={props.logo} alt="Company Logo" />
            }
            <h1 className="primary-banner__heading">{props.headingText}</h1>
            {props.subtitle &&
                <h2 className="primary-banner__subtitle">{props.subtitle}</h2>
            }
            <div className="primary-banner__button-container">
                {props.buttonPrimaryText &&
                    <Link to={props.buttonPrimaryUrl} className={`button-primary primary-banner__button-primary ${props.buttonPrimaryClass}`}>{props.buttonPrimaryText}</Link>
                }
                {props.buttonSecondaryText &&
                    <Link to={props.buttonSecondaryUrl} className={`button-secondary button-secondary--dark primary-banner__button-secondary ${props.buttonSecondaryClass}`}>{props.buttonSecondaryText}</Link>
                }
            </div>
        </div>
    </div>
  )
}
