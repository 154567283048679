import React from "react"
import './faq-block.scss'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

export default function FaqBlock(props) {
  if (!props.faqBlock) return null
  if (!Array.isArray(props.faqBlock)) return null
  return (
    <div className="faq-block">
      <div className="faq-block__container container-fluid">
        <h2 className="faq-block__heading">{props.faqBlockHeading.titleText}</h2>
        {props.faqBlock.map((faqBlock) => {
          return (
            <details className="faq-block__details">
                <summary className="faq-block__summary" aria-label="Menu" role="button" aria-expanded="false" aria-controls="menu-drawer">
                  <h2 className="faq-block__block-heading">{faqBlock.questionText}</h2>
                  <svg className="faq-block__summary-open" width="35" height="21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m24.92 0-1.64 1.687 6.956 7.158H0v2.386h30.236l-6.957 7.157 1.64 1.687 9.756-10.037L24.919 0Z" fill="#fff"/></svg>
                  <svg className="faq-block__summary-close" width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" d="m10.808 9.393 19.8 19.8-1.415 1.414-19.8-19.8z"/><path fill="#fff" d="m9.394 29.192 19.799-19.8 1.414 1.415-19.8 19.799z"/></svg>
                </summary>
                <div className="faq-block__block-description">{faqBlock.answerText?.raw && renderRichText(faqBlock.answerText)}</div>
            </details>   
          )
        })}
      </div>
    </div>
  )
}
