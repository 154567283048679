import React from 'react'
import './solutions-block-ecompare.scss'

export default function SolutionsBlockECompare({ solutions }) {
    return (
        <div className="container-fluid--solutions">
            <div className="solutions-block__heading-container">
                <div className="heading">
                    <h2 className="heading-title">{solutions.headingTitle}</h2>
                    <p className="heading-description">{solutions.headingSubtitle}</p>
                </div>
            </div>
            <div className="solutions-block-ecompare">
                <div className="solutions-block__solution-container solutionOne">
                    <div className="solutions-block__solution-container-icon">
                        <img className="solution-icon" src={solutions.iconOne.url} width="40" height="40" alt="eCompete Data Platform icon" title="eCompete Data Platform"/>
                    </div>
                    <div className="solutions-block__solution-container-info">
                        <h1 className="solution-title">{solutions.titleOne}</h1>
                        <p className="solution-description">{solutions.descriptionOne}</p>
                    </div>
                </div>

                <div className="solutions-block__solution-container solutionTwo">
                    <div className="solutions-block__solution-container-icon">
                        <img className="solution-icon" src={solutions.iconTwo.url} width="40" height="40" alt="eCompete Data Platform icon" title="eCompete Data Platform"/>
                    </div>
                    <div className="solutions-block__solution-container-info">
                        <h1 className="solution-title">{solutions.titleTwo}</h1>
                        <p className="solution-description">{solutions.descriptionTwo}</p>
                    </div>
                </div>

                <div className="solutions-block__solution-container solutionThree">
                    <div className="solutions-block__solution-container-icon">
                        <img className="solution-icon" src={solutions.iconThree.url} width="40" height="40" alt="eCompete Data Platform icon" title="eCompete Data Platform"/>
                    </div>
                    <div className="solutions-block__solution-container-info">
                        <h1 className="solution-title">{solutions.titleThree}</h1>
                        <p className="solution-description">{solutions.descriptionThree}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
