import React from 'react'
import './items-key-features.scss'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

export default function ItemsKeyFeatures( props ) {
    if (!props.ItemsKeyFeatures) return null
    if (!Array.isArray(props.ItemsKeyFeatures)) return null
    return (
        <div className={`items-key-features ${props.itemsKeyFeaturesBlockClass}`}>
            <div className="container-fluid">
                <h2 className="items-key-features__heading">{props.itemsKeyFeaturesHeading.titleText}</h2>
                {props.itemsKeyFeaturesHeading.descriptionText &&
                    <p className="items-key-features__heading-description">{props.itemsKeyFeaturesHeading.descriptionText}</p>
                }
                <div className="items-key-features__container">
                    {props.ItemsKeyFeatures.map((ItemsKeyFeatures) => {
                        return (
                            <div className='items-key-features__block'>
                                <div className='items-key-features__block-image-container'>
                                    <img src={ItemsKeyFeatures.image.url} width="255" height="200" title="Phase Image" alt="PhaseImage"/>
                                </div>
                                <div className='items-key-features__block-info-container'>
                                    <h2 className="heading">{ItemsKeyFeatures.headingText}</h2>
                                    <div className="description">{ItemsKeyFeatures.descriptionText?.raw && renderRichText(ItemsKeyFeatures.descriptionText)}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
